<template>
  <app-timeline>
    <app-timeline-item v-for="item in equipmentLog" :key="item.movimientoEquipoId" :variant="resolveVariant(item.movimientoEquipoTipo.nombre)">
      <div>
        {{ item.movimientoEquipoTipo.nombre }}
      </div>
      <div>
        {{ item.descripcion }}
      </div>
      <div>
        {{ item.fechaHoraMovimiento }}
      </div>
      <div>
        {{ item.nombreUsuario }}
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import { ref } from '@vue/composition-api'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import tiService from '@/services/informationTecnology.service'
import router from '@/router'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  setup() {
    const { fetchLogEquipment } = tiService()
    const equipmentLog = ref([])

    fetchLogEquipment(router.currentRoute.params.equipoId, data => {
      equipmentLog.value = data
    })

    const resolveVariant = event => {
      if (event === 'Alta inventario') return 'primary'
      if (event === 'Asignación empleado') return 'info'
      if (event === 'Desasignación empleado') return 'warning'
      if (event === 'Desecho') return 'warning'
      if (event === 'Obsoleto') return 'info'
      if (event === 'En reparación') return 'warning'
      if (event === 'Dañado') return 'danger'
      return ''
    }

    return {
      equipmentLog,
      resolveVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
