<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab>
          <template #title>
            <feather-icon icon="TagIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{  $t('Routes.editEquipment')  }}</span>
          </template>
          <equipment-edit-tab/>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="CheckSquareIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{  $t('AppTI.equipment.logEquipment')  }}</span>
          </template>
          <equipment-log-tab/>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{  $t('AppTI.equipment.documents')  }}</span>
          </template>
          <equipment-document-tab/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import EquipmentEditTab from './EquipmentEdit.vue'
import EquipmentLogTab from './EquipmentLog.vue'
import EquipmentDocumentTab from './EquipmentDocument.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    EquipmentEditTab,
    EquipmentLogTab,
    EquipmentDocumentTab,
  },

}
</script>
